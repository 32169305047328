<template>
  <!-- 未来lab -->
  <div class="website-css">
    <MenuHead @func="getMsgFormSon"></MenuHead>
    <!-- banner beg -->
    <div class="Solution-banner AboutUs-banner">
      <div class="w1200">
        <img
            src="../assets/image/Website-img/labbanner2.png"
            class="banner-icon banner-icon-lab1 animated flash"
        />
        <img
            src="../assets/image/Website-img/labbanner1.png"
            class="banner-icon banner-icon-lab2 animated pulse"
        />
        <div
            class="banner-title solution-title Mobile-pad25 animated fadeInLeft"
        >
          <p class="font48">{{ this.languageList.bannertitle }}</p>
          <p>
            {{ this.languageList.bannerContent }}<br/>
            {{ this.languageList.bannerContent1 }}
          </p>
        </div>
        <!-- <img src="../assets/image/Website-img/bannericon1.png" class="banner-icon3" /> -->
      </div>
    </div>
    <!-- banner end -->

    <!-- 宣传图三个 beg -->
    <div class="school-icon height-auto" style="padding-left: 0.7rem">
      <div class="w1200 Mobile-pad25">
        <ul class="Research-ul">
          <li
              v-for="(item, index) in this.languageList.ResearchField"
              :key="index"
          >
            <div><img :src="item.img" style="margin-right: 20px"/></div>
            <p>{{ item.title }}</p>
            <p>{{ item.content }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 宣传图三个 end -->

    <!-- 数字疗法的定义 beg -->
    <div style="background: #f8f8f8;">
      <div class="w1200 Mobile-pad25">
        <div class="brain">
          <div class="title-font36">
            {{ this.languageList.titleText }}
            <span
                style="width: 105px;left: 50%;margin-left: -52.5px;margin-top: 8px;position: absolute;display: block;border: 2px solid #63b7bc;"></span>
          </div>
          <div class="title-font18">
            {{ this.languageList.textContent }}
          </div>
          <div class="lab-brain">
            <img src="../assets/image/Website-img/futureLabPT.png" class="lab-img1 animated pulse"/>
          </div>
        </div>
      </div>
    </div>
    <!-- 数字疗法的定义 end -->

    <!-- 数字疗法的优势 beg -->
    <div class="title-font36">
      {{ this.languageList.casesTitle }}
      <span
          style="width: 105px;left: 50%;margin-left: -52.5px;margin-top: 8px;position: absolute;display: block;border: 2px solid #63b7bc;"></span>
    </div>
    <div class="title-font18" style="text-align: center;padding-left: 30%;padding-right: 30%">
      {{ this.languageList.caseContent }}
    </div>
    <div class="w1200 Mobile-pad25">
      <ul class="case-ul">
        <li v-for="(item, index) in this.languageList.cases" :key="index">
          <div class="case-text">
            <!--            <img :src="item.iconimg"/>-->
            <p>{{ item.title }}</p>
          </div>
          <img :src="item.img"/>
        </li>
      </ul>
    </div>
    <!-- 数字疗法的优势 end -->
    <web-bottom ref="child"></web-bottom>
  </div>
</template>
<script>
import '../assets/style/Public-css/img-style.css'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
import {WOW} from 'wowjs'
import MenuHead from '../components/MenuHead'
import WebBottom from '../components/WebBottom'

export default {
  name: 'futureLab',
  components: {
    MenuHead,
    WebBottom,
  },
  metaInfo: {
    title: '未来Lab-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keyWords',
        content:
            '100+合作高校及机构,数十个科研应用案例,海量认知行为大数据分析,云计算核心算法模型,强有力的数据研究报告,成熟科学理论支撑',
      },
      {
        name: 'description',
        content:
            '北京大象智酷科技有限公司致力于以前沿脑科学研究成果为基础，依托未来之光系列产品的海量数据，形成内部算法和模型，向脑科学、认知神经科学等相关科研机构及高校提供技术和数据支持。从而推动脑科学、认知神经科学等领域的科学研究和科研成果转化。',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
    }
  },
  props: {
    msg: String,
  },
  watch: {
    cases() {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        var wow = new WOW({
          live: false,
        })
        wow.init()
      })
    },
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // console.log("语言类型", this.langtype);
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      console.log('语言类型', this.langtype)
      this.IFType()
    }
  },
  mounted() {
    new WOW().init()
  },
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        console.log('中文配表', this.languageJson)
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      console.log('home', data)
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      console.log('中文配表', this.languageJson)
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'futureLab') {
          this.languageList = item.List[0]
        }
        console.log(this.languageList)
      })
    },
  },
}
</script>
<style scoped>
.Solution-banner {
  width: 100%;
  height: 535px;
  background: linear-gradient(to right, #34D3D5, #137680);
}

.Solution-banner {
}

.brain-ul li {
  float: left;
  width: 400px;
  height: 300px;
}
</style>
